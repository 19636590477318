@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.roboto-thin {
    font-family: "Roboto", sans-serif!important;
    font-weight: 100!important;
    font-style: normal!important;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif!important;
    font-weight: 100!important;
    font-style: italic!important;
  }
  
  .roboto-light {
    font-family: "Roboto", sans-serif!important;
    font-weight: 300!important;
    font-style: normal!important;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif!important;
    font-weight: 300!important;
    font-style: italic!important;
  }
  
  .roboto-regular {
    font-family: "Roboto", sans-serif!important;
    font-weight: 400!important;
    font-style: normal!important;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif!important;
    font-weight: 400!important;
    font-style: italic!important;
  }
  
  .roboto-medium {
    font-family: "Roboto", sans-serif!important;
    font-weight: 500!important;
    font-style: normal!important;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif!important;
    font-weight: 500!important;
    font-style: italic!important;
  }
  
  .roboto-bold {
    font-family: "Roboto", sans-serif!important;
    font-weight: 700!important;
    font-style: normal!important;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif!important;
    font-weight: 700!important;
    font-style: italic!important;
  }
  
  .roboto-black {
    font-family: "Roboto", sans-serif!important;
    font-weight: 900!important;
    font-style: normal!important;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", sans-serif!important;
    font-weight: 900!important;
    font-style: italic!important;
  }